.select-wrapper {
    position: relative;
    background-color: white;
    cursor: pointer;
    * {
        cursor: pointer;
    }
    select {
        background-color: white;
        margin-left: 4px;
    }
}

select {
    appearance: none;
}

input {
    &:focus {
        outline: none;
    }
}

// remove the up/down arrows for the phone no field in the form
input[type=number] {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}

