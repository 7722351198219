.enquire-wizard {
    background-color: white;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(61, 65, 82, 0.20);
    max-width: 400px;
    // min-height: 515px;
    margin-left: auto;
    margin-top: 58px;

    >button {
        margin-top: auto;
    }

    .single-form {
        .select {
            color: #8F8A8C;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .error-text {
        color: #DB3B21;
    }

    @media only screen and (max-width: 1023px) {
        margin-right: auto;
        margin-top: 0;
    }
}

.enquire-wizard-itinerary {
    background-color: white;
    border-radius: 18px;
    background: #FFF;
    max-width: 100%;

    >button {
        margin-top: auto;
    }

    .single-form {
        .select {
            color: #8F8A8C;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

textarea {
    border-radius: 14px;
    border: 1px solid #D7D7D7;
    padding: 12px;
    resize: none;
}